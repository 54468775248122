/* eslint-disable no-undef */
import React, { useState } from 'react';
import { verifyEmail, isNotEmpty } from 'utils/validators';
import { Button, notification } from 'antd';

import { sendVerificationCode } from 'services/apiService';

const form = (props) => {
  const urlPmAdmin =
    process.env.REACT_APP_PM_ADMIN != undefined
      ? process.env.REACT_APP_PM_ADMIN
      : '';

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    company_name: ''
  });
  const [errors, setErrors] = useState({});

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (message) => {
    api.info({
      message: `Error`,
      description: message,
      placement: 'topRight'
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (verifyEmail(formData.email)) {
      try {
        props.onLoader(true);
        let respose = await sendVerificationCode({
          pm_uuid_info: props?.pm_id != undefined ? props.pm_id : null,
          owner_first_name: formData.first_name,
          owner_last_name: formData.last_name,
          owner_email: formData.email,
          company_name: formData.company_name
        });
        if (respose) {
          let pm_id =
            props?.pm_id != undefined
              ? props.pm_id
              : respose.data?.pm_uuid_info;
          const params = `?pm_id=${pm_id}&first_name=${formData.first_name}&last_name=${formData.last_name}&email=${formData.email}&company_name=${formData.company_name}`;
          window.location.href = `${urlPmAdmin}/verification-otp/${params}`;
        } else {
          props.onLoader(false);
        }
      } catch (error) {
        props.onLoader(false);
        if (error?.response?.status == 401) {
          console.log('UnAuthorize');
        } else {
          console.log(
            'error?.response?.data?.detail2',
            error?.response?.data?.detail
          );
          error?.response?.data?.detail &&
            openNotification(error?.response?.data?.detail);
        }
      }
    }
    return;
  };

  // const nextPage = () => {
  //   props.setCurrentPage('verify');
  // };

  const handleChange = (fieldName, value) => {
    switch (fieldName) {
      case 'first_name':
        if (!isNotEmpty(value)) {
          setErrors((prev) => ({
            ...prev,
            first_name: 'This field is required.'
          }));
        } else {
          setErrors((prev) => ({
            ...prev,
            first_name: ''
          }));
        }
        break;

      case 'email':
        if (!verifyEmail(value)) {
          setErrors((prev) => ({
            ...prev,
            email: 'Please verify your email.'
          }));
        } else {
          setErrors((prev) => ({
            ...prev,
            email: ''
          }));
        }
        break;

      default:
        break;
    }

    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value
    }));
  };

  const clearEmail = (fieldName, value) => {
    let newValue = value.replace(/\s/g, '');

    handleChange(fieldName, newValue);
  };

  return (
    <>
      {contextHolder}
      {props?.title && (
        <h3 className="blue-title" style={{ textAlign: 'center' }}>
          {props.title}
        </h3>
      )}
      <form className="form-email" onSubmit={handleSubmit}>
        {/* field start */}
        <div className="field-content">
          <label htmlFor="email" className="hidden">
            Email:
          </label>
          <input
            type="text"
            id="email"
            name="email"
            value={formData.email}
            onChange={(e) => clearEmail('email', e.target.value)}
            pattern="[^\s]+"
            placeholder="Your email here..."
          />
        </div>
        {/* field end */}

        <div className="button-container">
          <Button
            type="submit"
            htmlType="submit"
            className="save-button"
            id="save-button"
            data-testid="save-button"
            style={{ margin: 0 }}
          >
            Update Subscription
          </Button>
        </div>
      </form>
      {errors.email && (
        <p id="email-error" data-testid="email" className="claim-error-msg">
          {errors.email}
        </p>
      )}
    </>
  );
};

export default form;
