import React, { useState } from 'react';

import Form from './Form';
import CircularProgress from 'components/CircularProgress';
import { Alert } from 'antd';

const FirstSection = () => {
  const [loader, setLoader] = useState(false);

  return (
    <>
      {loader && (
        <div className="gx-loader-view">
          <CircularProgress />
        </div>
      )}

      <section>
        <div className="hero-container">
          <h1>Welcome to the Owner Benefit Package™</h1>
          <p className="hero-text">
          Through multiple protections and property monitoring technology, OBP™ benefits and services give owners peace of mind in the face of tenant risk, unforeseen damages, and potential financial hardships, such as eviction, tenant defaults, squatters, or forced sale of the property due to need for liquidity.
          </p>

          <div className="button-group flex justify-content align-items center-button-force">
            <Form
              onLoader={(status) => {
                setLoader(status);
              }}
            />

            {/* <Button
            type="button"
            className="save-button"
            id="save-button"
            data-testid="got-to"
            style={{ margin: 0 }}
            onClick={onButtonClick}
          >
            Update subscription
          </Button> */}
          </div>

          <Alert
            message="If you’d like to change your subscription, please enter your account
            email and verification step to complete the subscription management
            flow"
            type="info"
            showIcon
            className='alert-info'
          />
        </div>
      </section>
    </>
  );
};

export default FirstSection;
