import React from 'react';
import ObpCost from '../ObpCost';

const CalculatorSection = () => {
  return (
    <section id="second-component" className="dynamic-second-section">
      <h2 className="blue-title w-content">Cost of OBP™ vs an Eviction</h2>
      <ObpCost />
    </section>
  );
};

export default CalculatorSection;
