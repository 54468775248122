import React from 'react';

const SecondSection = () => {
  // const ContentArray = [
  //   {
  //     id: 1,
  //     bold: '2 Months of Rent Protection:',
  //     description:
  //       'Receive up to 2 months of protected rental income in the event of a tenant defaulting on their rent payment. Mitigate risk and protect your cashflow with OBP™'
  //   },
  //   {
  //     id: 2,
  //     bold: 'Rent Advance™:',
  //     description:
  //       'A true guarantee on future rental income paid upfront! Rent Advance™ provides property owners with up to 12 months of rent payments upfront on qualifying leases. Rent Advance™ is non-recourse, so if a tenant defaults, you KEEP YOUR ADVANCE! Learn More'
  //   },
  //   {
  //     id: 3,
  //     bold: 'Rent Advance™:',
  //     description:
  //       'A true guarantee on future rental income paid upfront! Rent Advance™ provides property owners with up to 12 months of rent payments upfront on qualifying leases. Rent Advance™ is non-recourse, so if a tenant defaults, you KEEP YOUR ADVANCE! Learn More'
  //   },
  //   {
  //     id: 4,
  //     bold: '$1,000 in Legal and Re-Tenanting Expenses:',
  //     description:
  //       'Provide rental owners with up to $1,000 towards the listing of a unit, legal costs of an eviction, or other legal action, following a tenant’s default on rent. Why should owners pay out of pocket when OBP™ will cover this cost?!'
  //   },
  //   {
  //     id: 5,
  //     bold: '',
  //     description: 'Additional Benefits that Property Managers Love'
  //   },
  //   {
  //     id: 6,
  //     bold: 'Market and Asset Level Reporting:',
  //     description:
  //       'Our rental report provides your clients with access to accurate rental listing comps in their market. Generate a personal report that assess the rental value for each of your subscribed clients'
  //   },
  //   {
  //     id: 7,
  //     bold: 'Real-Time Alerts on Rental Housing Legislation:',
  //     description:
  //       'As new legislation is proposed, keep you clients updated on how the proposed bill may impact their rental investment. Federal and State legislation included.'
  //   }
  // ];

  const prevArray = [
    {
      id: 1,
      bold: '2 Months of Rent Protection:',
      description:
        'Receive up to 2 months of protected rental income in the event of a tenant defaulting on their rent payment. Mitigate risk and protect your cash flow with OBP™.'
    },
    {
      id: 2,
      bold: '$1,500 in Legal and Re-tenanting Expenses, including Pet Damage:',
      description:
        'Up to $1,500 towards the listing of a unit, legal costs of an eviction, other legal action, or damages, following a tenant’s default on rent. Why should owners pay out of pocket when OBP™ covers this and gives you peace of mind?'
    },
    {
      id: 3,
      bold: '$3,000 in Unauthorized Occupant Protection:',
      description:
        'Up to $3,000 towards costs incurred as a result of an unauthorized occupant (“squatter”) that prevents occupying the unit with a tenant in good standing. Protect yourself from the potential cost of this unfortunate surprise and stressful situation.'
    },
    {
      id: 4,
      bold: 'Market and Asset Level Reporting:',
      description:
        'Our rental report provides accurate rental listing comps in their market.'
    },
    {
      id: 5,
      bold: 'Fraudulent Listings Monitoring and Removal plus Lease Compliance Monitoring:',
      description:
        'Prevent squatters when units are vacant, as well as short-term homestays posted by tenants.'
    },
    {
      id: 6,
      bold: 'Real-Time Alerts on Rental Housing Legislation:',
      description:
        'As new legislation is proposed, stay updated on how the proposed bill may impact a rental investment. Federal and State legislation included.'
    }
  ];

  const CustomLi = ({ id, boldContent, regularContent }) => {
    return (
      <div className="custom-li" key={id}>
        {boldContent != '' && (
          <img height={35} width={35} src="icons/circle-box.png" alt="" />
        )}
        <p>
          <strong>{boldContent}</strong> {regularContent}
        </p>
      </div>
    );
  };

  return (
    <section className="landing-second-section">
      <div className="image-container">
        <img className="obp-house" src="images/ob-2.png" />
      </div>
      <div className="info-container">
        <h2>
          OBP™ offers property owners higher quality property management,
          stability, and protection against potential losses from tenant default
        </h2>
        <p>What property owners love about OBP™</p>
        {prevArray.map((item) => (
          <CustomLi
            key={item.id}
            boldContent={item.bold}
            regularContent={item.description}
          />
        ))}
      </div>
    </section>
  );
};

export default SecondSection;
