import React from 'react';

const FirstSection = () => {
  const obpFeaturesList = [
    'Up to 2 months of rent protection*',
    'Up to $1,500 in legal and re-tenanting expenses, including pet damages',
    'Up to $3,000 in unauthorized occupant (“squatter”) protection',
    'Fraudulent listings monitoring and removal',
    'Lease compliance monitoring',
    'Market and asset level reporting',
    'Real-time legislative alerts on rental housing laws'
  ];

  return (
    <section className="landing-first-section">
      <div className="white-container">
        <h1 className="title">What is the Owner Benefit Package™?</h1>
        <p>
          The Owner Benefit Package™ is an innovative property management
          offering that provides a comprehensive range of protections and
          services delivering additional value to property investors.
        </p>
        <h2 className="subtitle">What’s included in OBP™?</h2>
        <ol>
          {obpFeaturesList.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ol>
        <p className="ghost-text">
          *Benefit only offered on specific package types outlined in OBP™
          subscription management details
        </p>
      </div>
    </section>
  );
};

export default FirstSection;
